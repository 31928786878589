import { Widget } from '../../core/widget/widget.js';
import '../../core/widget/events.js';
import { AgreementsDialogInternalEvents, AgreementsDialogPublicEvents } from './events.js';
import { getAgreementsDialogTemplate } from './template.js';
class AgreementsDialog extends Widget {
  vkidAppName = 'user_policy_agreements';
  templateRenderer = getAgreementsDialogTemplate;
  onBridgeMessageHandler(event) {
    switch (event.handler) {
      case AgreementsDialogInternalEvents.DECLINE:
        {
          this.close();
          break;
        }
      case AgreementsDialogInternalEvents.ACCEPT:
        {
          this.events.emit(AgreementsDialogPublicEvents.ACCEPT, event);
          break;
        }
      default:
        super.onBridgeMessageHandler(event);
        break;
    }
  }
}
export { AgreementsDialog };