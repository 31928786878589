import { validator } from '../../core/validator/validator.js';
import { isNotEmptyOAuthList } from '../../core/validator/rules.js';
import { Widget } from '../../core/widget/widget.js';
import '../../core/widget/events.js';
import { WidgetState } from '../../core/widget/types.js';
import { getOAuthListTemplate } from './template.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function _ts_decorate(decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
class OAuthList extends Widget {
  render(params) {
    this.templateRenderer = getOAuthListTemplate({
      lang: params.lang,
      oauthList: params.oauthList,
      height: params.styles?.height,
      borderRadius: params.styles?.borderRadius,
      scheme: params.scheme
    });
    this.container = params.container;
    this.renderTemplate();
    this.registerElements();
    this.setState(WidgetState.LOADED);
    this.elements.root.addEventListener('click', this.handleClick.bind(this));
    return this;
  }
  handleClick(e) {
    const target = e.target.closest('[data-oauth]');
    if (!target) {
      return;
    }
    const oauth = target.getAttribute('data-oauth');
    OAuthList.__auth.login({
      action: {
        name: 'sdk_oauth',
        params: {
          oauth
        }
      }
    });
  }
}
_ts_decorate([validator({
  oauthList: [isNotEmptyOAuthList]
})], OAuthList.prototype, "render", null);
export { OAuthList };