import { validator } from '../../core/validator/validator.js';
import { isValidHeight } from '../../core/validator/rules.js';
import { Widget } from '../../core/widget/widget.js';
import '../../core/widget/events.js';
import { WidgetState } from '../../core/widget/types.js';
import { Languages, Scheme } from '../../types.js';
import { AgreementsDialog } from '../agreementsDialog/agreementsDialog.js';
import { AgreementsDialogPublicEvents } from '../agreementsDialog/events.js';
import { OAuthList } from '../oauthList/oauthList.js';
import { OAuthName } from '../oauthList/types.js';
import { OneTapInternalEvents } from './events.js';
import { getOneTapTemplate } from './template.js';
function _ts_decorate(decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
const defaultStylesParams = {
  width: 0,
  height: 44,
  borderRadius: 8
};
const BUTTON_SPACING = 12;
class OneTap extends Widget {
  vkidAppName = 'button_one_tap_auth';
  onBridgeMessageHandler(event) {
    switch (event.handler) {
      // TODO: hidePreloadButton на событие ошибки
      case OneTapInternalEvents.LOGIN_SUCCESS:
        {
          this.redirectWithPayload(event.params);
          break;
        }
      case OneTapInternalEvents.SHOW_FULL_AUTH:
        {
          const params = {};
          if (event.params.screen) {
            params.screen = event.params.screen;
          }
          if (event.params.sdk_oauth) {
            params.action = {
              name: 'sdk_oauth',
              params: {
                oauth: event.params.sdk_oauth
              }
            };
          }
          this.openFullAuth(params);
          break;
        }
      case OneTapInternalEvents.NOT_AUTHORIZED:
        {
          this.setState(WidgetState.NOT_LOADED);
          clearTimeout(this.timeoutTimer);
          this.elements?.iframe?.remove();
          break;
        }
      case OneTapInternalEvents.SHOW_AGREEMENTS_DIALOG:
        {
          this.createAgreementsDialogWidget();
          break;
        }
      default:
        {
          super.onBridgeMessageHandler(event);
          break;
        }
    }
  }
  createAgreementsDialogWidget() {
    const params = {
      container: document.body,
      lang: this.lang,
      scheme: this.scheme
    };
    const agreementsDialog = new AgreementsDialog();
    const acceptHandler = event => {
      this.bridge.sendMessage({
        handler: OneTapInternalEvents.START_AUTHORIZE,
        params: event.params
      });
      agreementsDialog.off(AgreementsDialogPublicEvents.ACCEPT, acceptHandler);
      agreementsDialog.close();
    };
    agreementsDialog.on(AgreementsDialogPublicEvents.ACCEPT, acceptHandler);
    agreementsDialog.render(params);
  }
  openFullAuth(value) {
    const params = {
      ...value,
      lang: this.lang,
      scheme: this.scheme
    };
    OneTap.__auth.login(params);
  }
  renderOAuthList(params) {
    if (!params.oauthList.length) {
      return;
    }
    const oauthList = new OAuthList();
    oauthList.render(params);
  }
  render(params) {
    this.lang = params?.lang || Languages.RUS;
    this.scheme = params?.scheme || Scheme.LIGHT;
    const providers = (params.oauthList || []).filter(provider => provider !== OAuthName.VK);
    const oneTapParams = {
      style_height: params.styles?.height || defaultStylesParams.height,
      style_border_radius: params.styles?.borderRadius || defaultStylesParams.borderRadius,
      show_alternative_login: params?.showAlternativeLogin ? 1 : 0,
      button_skin: params.skin || 'primary',
      scheme: this.scheme,
      lang_id: this.lang,
      providers: providers.join(',')
    };
    this.templateRenderer = getOneTapTemplate({
      width: params.styles?.width || defaultStylesParams.width,
      iframeHeight: oneTapParams.show_alternative_login ? oneTapParams.style_height * 2 + BUTTON_SPACING : oneTapParams.style_height,
      height: oneTapParams.style_height,
      borderRadius: oneTapParams.style_border_radius,
      openFullAuth: this.openFullAuth.bind(this),
      skin: oneTapParams.button_skin,
      scheme: oneTapParams.scheme,
      lang: oneTapParams.lang_id,
      renderOAuthList: this.renderOAuthList.bind(this),
      providers
    });
    return super.render({
      container: params.container,
      ...oneTapParams
    });
  }
}
_ts_decorate([validator({
  styles: [isValidHeight]
})], OneTap.prototype, "render", null);
export { OneTap };