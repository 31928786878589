import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import { nanoid } from './../lib/nanoid/non-secure/index.js';

/**
 * Генерация code challenge для нового oauth
 */
const generateCodeChallenge = () => {
  const codeVerifier = nanoid();
  const hash = sha256(codeVerifier);
  const base64 = Base64.stringify(hash);
  return base64.replace(/=*$/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};
export { generateCodeChallenge };